<template>
    <div>
        <div class="page-group-box">
            <div class="page-group-title">基本信息 <small v-if="disabled">众筹暂不支持编辑，有任何疑问请联系秀动客服</small></div>
            <el-row :gutter="24">
				<el-col v-if="formData.status == 5 " :xs="24" :xl="16">
					<el-form-item>
						<span class="color-danger ">审核未通过</span>				    
						<span v-if="formData.status == 5 " class="color-danger">: {{formData.description}}</span>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :xl="16">
                    <el-form-item prop="poster" label="众筹封面:" :rules="[{required: true, message: '请上传众筹封面'}]" :show-message="formData.poster ? false : true">
                        <image-upload 
                            :url.sync="formData.poster" 
                            :urlFull.sync="formData.poster" 
                            :original.sync="formData.posterOriginal"
                            :crop="true"
                            :min-width="1006"
                            :min-height="660"
                            ref="posterUpload"
                            class="poster-upload"
                        >
                        </image-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="raiseType" label="众筹类型:" >
                        <el-select v-model="formData.raiseType" size="medium">
                            <el-option :value="1" label="专辑唱片"></el-option>
                            <el-option :value="2" label="音乐周边"></el-option>
                            <el-option :value="3" label="其它"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="title" label="众筹名称:" :rules="[{required: true, message: '请输入众筹名称'}]">
                        <el-input v-model="formData.title" placeholder="请输入众筹名称" size="medium" maxlength="100"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="raiseMoney" label="目标众筹金额:" :rules="[{required: true, message: '请输入目标众筹金额', trigger:'blur'}]">
                        <el-input-number style="width:100%" v-model="formData.raiseMoney" :precision="2" controls-position="right" :min="0.00" :max="99999999.99" size="medium" placeholder="请输入目标众筹金额"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="datetimerange" class="has-tips" :rules="[{required: true, message: '请选择开始结束时间'}]">
                        <template slot="label">
                            <span>开始结束时间: </span>
                            <div class="tips">
                                <el-tooltip effect="dark" placement="top-start">
                                    <div slot="content">您可以设置未来的某个时间节点才正式开始众筹(前提是已经通过审核)<br/>如果想要通过审核后立即开始，请将开始时间设置为当前或者已经过去的任一时间节点即可</div>
                                    <span>您可以设置未来的某个时间节点才正式开始众筹(前提是已经通过审核)。如果想要通过审核后立即开始，请将开始时间设置为当前或者已经过去的任一时间节点即可</span>
                                </el-tooltip>
                            </div>
                        </template>
                        <el-date-picker 
                            v-model="formData.datetimerange" 
                            type="datetimerange" 
                            start-placeholder="开始时间" 
                            end-placeholder="结束时间" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            size="medium"
                            :default-time="['00:00:00', '23:59:59']"
                            unlink-panels
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>    
            <el-row :gutter="24">
                <el-col :xs="24" :xl="16">
                    <el-form-item prop="raiseModel" label="众筹模式:" >
                        <div class="msg">
                            <div><small>普通模式(传统模式)：项目结束后，如果没有筹够目标筹款金额，将全额退款给支持者</small></div>
                            <div><small>预售模式：无论在设定时间内是否完成众筹目标，您都将获得所筹集到的资金，也需要提供承诺的相应回报给支持者</small></div>
                        </div>
                        <el-radio-group v-model="formData.raiseModel" size="medium" class="MT10">
                            <el-radio :label="1" border disabled="disabled">预售模式</el-radio>
                            <el-radio :label="2" border>普通模式</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="$store.state.userInfo.userType == 2" :gutter="24">
                <el-col :xs="24" :xl="16">
                    <el-form-item prop="musicIds" >
                        <span slot="label">
                            歌曲作品: 
                            <small v-if="!disabled && formData.musicIds.length > 1">按住鼠标可拖动排序</small>
                        </span>
                        <el-checkbox-group v-model="formData.musicIds" size="medium" @change="updateMusicId">
                            <el-row :gutter="12">
                                <vuedraggable v-model="formData.musics" draggable=".dragItem" :disabled="disabled" @change="updateMusicId">
                                    <span v-for="item in formData.musics" :key="item.id" class="dragItem">
                                        <el-checkbox :label="item.musicId" border>{{item.musicName}}</el-checkbox>
                                    </span>
                                </vuedraggable>
                            </el-row>
                        </el-checkbox-group>
                        <el-button type="primary" size="medium" @click="$refs.selectMusic.show()">从曲库选择歌曲</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        <div class="page-group-box MT10">
            <div class="page-group-title">众筹简介</div>
            <el-row :gutter="24">
                <el-col :xs="24" :xl="16">
                    <el-form-item prop="introduce" :rules="[{required: true, message: '请输入众筹简介'}]" :show-message="formData.introduce ? false : true">
                        <tinymce v-model="formData.introduce" :height="500" :toolbar="toolbar" :menubar="false" v-if="reFresh" class="MT20" :readonly="disabled"></tinymce>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        <select-music ref="selectMusic" :musics.sync="formData.musics" :musicIds.sync="formData.musicIds"></select-music>
    </div>
</template>

<script>
import ImageUpload from '@/components/ImageUpload';
import vuedraggable from 'vuedraggable';
import Tinymce from '@/components/Tinymce'; 
import SelectMusic from './select-music';
export default {
    props:{
       formData: {
            type: Object,
            default: null
        }, 
        reFresh: {
            type:Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components:{ImageUpload,vuedraggable,Tinymce, SelectMusic},
    
    data(){
        return{
            toolbar:['formatselect fontsizeselect | forecolor bold strikethrough | alignleft aligncenter alignright alignjustify | image media | preview fullscreen'],
        }
    },
    methods:{
        updateMusicId(){
            const _arry1 = this.formData.musicIds , _arry2 = [];
            this.formData.musics.forEach( item => {
                _arry1.forEach( v => {
                    if( item.musicId == v ){
                        _arry2.push( v )
                    }
                })
            })
           this.formData.musicIds = _arry2; //更新已选择歌曲
        },
        
    }
}
</script>

<style lang="scss" scoped>
.poster-upload{
    ::v-deep .image{
        width: 310px;
        height: 188px;
    }
    ::v-deep .image-upload-content{
        height: 188px;
    }
}
.page-group-title{
    small{
        font-size: 12px;
        color: $--color-danger;
    }
}
.msg{
    line-height: normal;
}
.dragItem{
    display: inline-block;
    margin: 0 6px 6px 6px;
}
</style>