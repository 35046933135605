<template>
    <el-drawer
        title="选择歌曲(最多选择10首)"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <div class="color-regular">在小站上传过的歌曲会出现在这里供选择</div>
            <el-checkbox-group v-model="checkedIds" :max="10" size="medium">
                <el-row :gutter="20" class="MT20">
                    <span v-for="v in musicList" :key="v.id" >
                        <el-checkbox :label="v.id" border>{{v.name}}</el-checkbox>
                    </span>
                </el-row>
            </el-checkbox-group>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submit()" :loading="btnLoading"
                >确 定</el-button
            >
        </div>
    </el-drawer>
</template>

<script>
export default {
    props:{
        musicIds:{
            type: Array,
            default: () => []
        },
        musics:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            drawerShow: false,
            dataLoading: false,
            btnLoading:false,
            musicList:null,
            checkedIds:[]
        }
    },
    created(){
        this.getData();
    },
    methods:{
        close() {
            this.drawerShow = false;
        },
        show(){
            this.checkedIds = this.musicIds;
            this.drawerShow = true;
        },
        getData(){
            this.dataLoading = true;
            this.$request({
                url: "/userCommon/musicList",
                data: { pageNo: 1, pageSize: 100},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.musicList = result.result;
                    } else {
                        self.$message.error(msg || "获取歌曲作品失败,请重试");
                    }
                })
                .catch(() => {
                    self.$message.error("获取歌曲作品失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        submit(){
            this.$emit('update:musicIds',[...this.checkedIds]);
            const _arry = [...this.musics] || [];
            this.musicList.forEach(item => {
                this.checkedIds.forEach( v => {
                    if(item.id === v){
                        if(!this.musics.find(x => x.musicId == item.id)){
                            _arry.push({
                                musicId: item.id,
                                musicName: item.name,
                            });
                        }
                    }
                })
            })
            this.$emit('update:musics',[..._arry]);
            this.close();
        }
    }
}
</script>

<style lang="scss" scoped>
.el-row{
    span{
        display: inline-block;
        margin: 5px 10px;
    }
}
</style>